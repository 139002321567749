import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isModalOpen: false,
  promotionalData: {},
};

const promotionalSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    updatePromotionalModalStatus: (state, action) => {
      state.isModalOpen = action.payload;
    },

    updatePromotionalData: (state, action) => {
      state.promotionalData = action.payload;
    },
  },
});

export default promotionalSlice.reducer;
export const { updatePromotionalModalStatus, updatePromotionalData } =
  promotionalSlice.actions;
