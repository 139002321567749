import React, { useEffect } from "react";
import Image from "next/image";
import RecentSearchModal from "@/components/modal/recent-search";
import useClickOutSide from "hooks/useClickOutSide";
import { useState } from "react";
import en from "@/localization/en.json";
import { useRouter } from "next/router";
import { motion, AnimatePresence } from "framer-motion";
import LocalStorage from "@/services/LocalStorage";
import { useGetProductSuggestions } from "@/api/client/product";
import SearchSuggestions from "@/components/search/searchSuggestion";
import { useDebounce } from "hooks/useDebounce";

function SearchForm() {
  const router = useRouter();
  const [searchKey, setSearchKey] = useState("");
  const [isRecentSearchModalOpen, setIsRecentSearchModalOpen] = useState(false);
  const [recentSearchData, setRecentSearchData] = useState([]);

  const [suggestionData, setSuggestionData] = useState([]);

  const domNode = useClickOutSide(() => setIsRecentSearchModalOpen(false));
  const debounceValue = useDebounce(searchKey, 500);

  const MAX_STORE_SEARCH_HISTORY_ITEMS = 8;

  const { refetch: suggestionRefetch, remove: removeSuggestionData } =
    useGetProductSuggestions(searchKey, {
      onSuccess: (suggestionData) => {
        setSuggestionData(suggestionData);
      },
      enabled: false,
    });

  useEffect(() => {
    setRecentSearchData(LocalStorage.getValue("amarpetRecentSearch") || []);
  }, []);

  useEffect(() => {
    setSearchKey(router.query?.query);
  }, [router.query?.query]);

  const handleSearchSubmit = (searchEvent, searchVal) => {
    searchEvent.preventDefault();
    if (!searchVal) return;
    router.push({
      pathname: "/search",
      query: { query: searchVal },
    });
    saveDataToLocalStorage(searchVal);
  };

  const saveDataToLocalStorage = (data) => {
    if (!data) return;
    let existed = recentSearchData.find((element) => element.value === data);
    if (existed) return;

    let updatedData = [{ value: data }, ...recentSearchData].slice(
      0,
      MAX_STORE_SEARCH_HISTORY_ITEMS
    );
    setRecentSearchData(updatedData);
    LocalStorage.addValue("amarpetRecentSearch", updatedData);
  };

  useEffect(() => {
    if (isRecentSearchModalOpen) suggestionRefetch();
    if (!debounceValue) removeSuggestionData();
  }, [isRecentSearchModalOpen, debounceValue]);

  const deleteSearchData = (value) => {
    let filteredData = recentSearchData.filter((data) => data.value !== value);
    setRecentSearchData(filteredData);
    LocalStorage.addValue("amarpetRecentSearch", filteredData);
  };

  const isModalViable = () => searchKey || isRecentSearchModalOpen;

  return (
    <div
      className="relative order-3 col-span-2 mt-[1.875rem] md:order-2 md:mt-0"
      ref={domNode}
    >
      <div className="relative h-[3rem] rounded-[2.625rem] border border-grayD5 md:w-[23rem] lg:w-[25rem] min-[1200px]:w-[30rem] 2xl:w-[45rem]">
        <form
          className="w-full h-full rounded-[2.625rem]"
          onClick={() => setIsRecentSearchModalOpen(true)}
          onSubmit={(e) => handleSearchSubmit(e, searchKey)}
        >
          <input
            type="text"
            className="w-[91%] md:w-[93%] lg:w-[96%] h-full rounded-[2.625rem] pl-4 border-0 focus:ring-0"
            placeholder={en["home.search_anything"]}
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
          />

          <button className="absolute top-[50%] right-[.8%] translate-x-[-50%] translate-y-[-40%]">
            <Image
              src="/assets/icons/search.svg"
              width={22}
              height={22}
              alt="search-field"
            />
          </button>
        </form>
      </div>
      <AnimatePresence>
        {isModalViable() && (
          <div>
            {!searchKey && recentSearchData.length > 0 && (
              <motion.div
                key="modal"
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="absolute z-[100] bg-white w-full border border-grayD6 rounded-[0.75rem] shadow-lg py-[1.5rem]"
              >
                <RecentSearchModal
                  recentSearchData={recentSearchData}
                  deleteSearchData={deleteSearchData}
                  setSelectedValue={(e, val) => {
                    handleSearchSubmit(e, val);
                    setSearchKey(val);
                  }}
                />
              </motion.div>
            )}
            {suggestionData.length > 0 &&
              searchKey &&
              isRecentSearchModalOpen && (
                <motion.div
                  key="modal"
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="absolute z-[100] bg-white w-full border border-grayD6 rounded-[0.75rem] shadow-lg py-[1.5rem]"
                >
                  <SearchSuggestions
                    key="searchSuggestions"
                    query={searchKey}
                    searchedProducts={suggestionData}
                  />
                </motion.div>
              )}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default SearchForm;
