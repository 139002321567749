import apiClient from "@/services/api-client";
import { useMutation } from "@tanstack/react-query";
import { errorLogger } from "@/utils/helpers/logger";

export const subscribeToNewsletter = (data) => {
  return apiClient({
    method: "post",
    url: `/v1/newsletter-subscription`,
    data,
  });
};

export const useSubscribeToNewsletter = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};
  return useMutation(subscribeToNewsletter, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        message: res.data.message,
      });
    },
    onError: (error) => {
      errorLogger.logApiError(error);
      onError({
        status: error.response.status,
        data: error.response.data,
        message: error.message,
      });
    },
  });
};
