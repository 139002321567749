import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishlistItemsCount: 0,
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    updateWishlistItemCount: (state, action) => {
      state.wishlistItemsCount = action.payload;
    },
  },
});

export default wishlistSlice.reducer;

export const { updateWishlistItemCount } = wishlistSlice.actions;
