import apiClient from "@/services/api-client";
import { errorLogger } from "@/utils/helpers/logger";

export const fetchAllBrands = async () => {
  try {
    const res = await apiClient.get(`/v1/brands`);
    if (res.status === 200) return res.data;
  } catch (error) {
    errorLogger.logApiError(error);
    return;
  }
};

export const fetchBrand = async (slug) => {
  try {
    const res = await apiClient.get(`/v1/brands/${slug}`);
    if (res.status === 200) return res.data;
  } catch (error) {
    errorLogger.logApiError(error);
    return;
  }
};
