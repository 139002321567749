export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
export const fbEvents = {
  addToCart: "AddToCart",
  addToWishList: "AddToWishlist",
  viewContent: "ViewContent",
  completeRegistration: "CompleteRegistration",
  contact: "Contact",
  initiateCheckout: "InitiateCheckout",
  purchase: "Purchase",
  search: "Search",
};

export const pageview = () => {
  if (!window?.fbq) return;

  window.fbq("track", "PageView");
};

export const sendFPixelEvent = (name, options = {}) => {
  window.fbq("track", name, options);
};

export const purchaseEvent = (amount = 0) => {
  window.fbq("track", fbEvents.purchase, {
    value: amount,
    currency: "BDT",
  });
};
