import * as Sentry from "@sentry/nextjs";
import { getAmarPetUser } from "./auth";

class ErrorLogger {
  constructor() {
    this.user = getAmarPetUser();
  }

  logApiError(error) {
    return error;
  }

  sentryErrorLog() {
    let requestData = error.request.__sentry_xhr_v2__;

    Sentry.captureException(error, (scope) => {
      scope.setTransactionName(`${error.response.data.message}`);
      scope.setTag("API-ERROR", "API-ERROR");

      scope.setExtras({
        user: {
          name: this.user?.full_name,
          email: this.user?.email_address,
          phone: this.user?.phone_number,
        },

        request: {
          url: error.request.responseURL,
          method: requestData?.method,
          body: JSON.stringify(requestData?.body),
        },

        response: JSON.stringify(error.response.data),
      });
      return scope;
    });
  }
}

export const errorLogger = new ErrorLogger();
