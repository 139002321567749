import { useRef, useEffect } from "react";

const useClickOutSide = (cb) => {
  const domeNode = useRef();

  const handelMouseEvent = (event) => {
    if (!domeNode.current?.contains(event?.target)) {
      if (cb) {
        cb();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handelMouseEvent);

    return () => document.removeEventListener("mousedown", handelMouseEvent);
  }, []);

  return domeNode;
};

export default useClickOutSide;
