import MinusIcon from "@/icons/minus.svg";
import PlusIcon from "@/icons/plus.svg";
import en from "@/localization/en.json";
import Image from "next/image";
import { contactInfo } from "@/utils/constants/supportInfo";
import { IS_SSHL_PAYMENT_ACTIVE } from "config/feature-flag/checkout";

import Link from "next/link";
import { useState } from "react";
import AppStoreLogo from "../data-display/logo/AppStore";
import PlayStoreLogo from "../data-display/logo/PlayStore";
import { MOBILE_APP_LINK } from "@/utils/constants/mobileAppLink";
import SslCommerceBanner from "../data-display/banner/SslCommerce";
import { EmailIcon, MapIcon, PhoneIcon } from "../data-display/Icon";
import { OFFICE_ADDRESS } from "@/utils/constants/officeAddress";
import OfficeDirectionBtn from "../action/button/OfficeDirectionBtn";

function Middle() {
  const [isShopDetailsOpen, setIsShopDetailsOpen] = useState(true);
  const [isCustomerServiceOpen, setIsCustomerServiceOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);

  return (
    <div className="px-7 lg:px-[7.5rem] pt-9 md:pt-11">
      <div className="grid text-grayD3 text-[0.938rem] md:grid-cols-12 md:items-start ">
        <div className="space-y-5 md:col-span-4">
          <div className="mb-6">
            <div className="hidden md:inline-block cursor-pointer">
              <Link href="/">
                <div>
                  <Image
                    src="/assets/icons/amar-pet.svg"
                    width={181}
                    height={48}
                    priority
                    alt="logo"
                  />
                </div>
              </Link>
            </div>

            <div className="flex justify-between items-center md:hidden">
              <p className=" text-grayD2 text-base font-semibold">
                {en["home.get_in_touch"]}
              </p>
              {isShopDetailsOpen ? (
                <MinusIcon
                  onClick={() => setIsShopDetailsOpen(!isShopDetailsOpen)}
                  className="md:hidden h-[1rem] w-[1rem] text-[#0a101a]"
                />
              ) : (
                <PlusIcon
                  onClick={() => setIsShopDetailsOpen(!isShopDetailsOpen)}
                  className="md:hidden h-[1rem] w-[1rem] text-[#0a101a]"
                />
              )}
            </div>
          </div>

          <div
            className={`${
              isShopDetailsOpen ? "" : "opacity-0 h-0  md:h-full md:opacity-100"
            } space-y-5 overflow-hidden transition-all delay-150 duration-300`}
          >
            <div className="flex items-start space-x-3.5">
              <MapIcon className="text-primary w-[16px] h-[19px] mt-[0.2rem]" />

              <div className="max-w-[18.75rem] space-y-2">
                <p className="space-x-1">
                  <span className="font-bold">Corporate Office:</span>
                  <span>{OFFICE_ADDRESS.corporate}</span>
                </p>
                <p className="space-x-1">
                  <span className="font-bold">Pickup Point:</span>
                  <span>{OFFICE_ADDRESS.pickup}</span>
                </p>
                <div>
                  <OfficeDirectionBtn officeLink={OFFICE_ADDRESS.pickup_map} />
                </div>
              </div>
            </div>

            <div className="flex items-start space-x-3.5">
              <PhoneIcon className="w-4 h-4 text-primary mt-[0.2rem]" />
              <div>
                <Link href={"tel:" + contactInfo.phone1}>
                  {contactInfo.phone1}
                </Link>
              </div>
            </div>

            <div className="flex items-center space-x-3.5">
              <EmailIcon className="w-4 h-4 text-primary" />

              <div>
                <Link href={"mailto:" + contactInfo.email}>
                  {contactInfo.email}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-9 md:mt-0 md:col-span-2 ">
          <div className="mb-[1.375rem] flex justify-between items-center mt-3">
            <p className=" text-grayD2 text-base font-semibold">About</p>
            <div className="md:hidden ">
              {isAboutOpen ? (
                <MinusIcon
                  onClick={() => setIsAboutOpen(!isAboutOpen)}
                  className="h-[1rem] w-[1rem] text-[#0a101a]"
                />
              ) : (
                <PlusIcon
                  onClick={() => setIsAboutOpen(!isAboutOpen)}
                  className="h-[1rem] w-[1rem] text-[#0a101a]"
                />
              )}
            </div>
          </div>

          <div
            className={`${
              isAboutOpen ? "" : "opacity-0 h-0  md:h-full md:opacity-100"
            } w-11/12 md:w-8/12  overflow-hidden transition-all delay-100 duration-350`}
          >
            <div className="space-y-4">
              <p className="hover:text-darkPrimary">
                <Link href="/contact-us">Contact Us</Link>
              </p>
              <p className="hover:text-darkPrimary">
                <Link href="/about-us">About Us</Link>
              </p>

              <p className="hover:text-darkPrimary">
                <Link href="/blogs">Blog</Link>
              </p>
              <p className="hover:text-darkPrimary">
                <Link href="/faq">{en["home.faqs"]}</Link>
              </p>
            </div>
          </div>
        </div>

        <div className="mt-9 md:mt-0 md:col-span-3 ">
          <div className="mb-[1.375rem] flex justify-between items-center mt-3">
            <p className=" text-grayD2 text-base font-semibold">
              {en["home.customer_service"]}
            </p>
            <div className="md:hidden ">
              {isCustomerServiceOpen ? (
                <MinusIcon
                  onClick={() =>
                    setIsCustomerServiceOpen(!isCustomerServiceOpen)
                  }
                  className="h-[1rem] w-[1rem] text-[#0a101a]"
                />
              ) : (
                <PlusIcon
                  onClick={() =>
                    setIsCustomerServiceOpen(!isCustomerServiceOpen)
                  }
                  className="h-[1rem] w-[1rem] text-[#0a101a]"
                />
              )}
            </div>
          </div>

          <div
            className={`${
              isCustomerServiceOpen
                ? ""
                : "opacity-0 h-0  md:h-full md:opacity-100"
            } w-11/12 md:w-8/12  overflow-hidden transition-all delay-100 duration-350`}
          >
            <div className="space-y-4">
              <p className="hover:text-darkPrimary">
                <Link href="/privacy-policy">{en["home.privacy_policy"]}</Link>
              </p>
              <p className="hover:text-darkPrimary">
                <Link href="/terms-of-use">{en["home.terms_of_use"]}</Link>
              </p>
              <p className="hover:text-darkPrimary">
                <Link href="/return-and-refund-policy">
                  Return & Refund policy
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="mt-9 md:mt-0 md:col-span-3  ">
          <div className="mb-[1.375rem] flex justify-between items-center mt-3">
            <p className=" text-grayD2 text-base font-semibold">Mobile Apps</p>
          </div>

          <div className="flex items-center space-x-6">
            <AppStoreLogo redirectUrl={MOBILE_APP_LINK.app_store} />
            <PlayStoreLogo redirectUrl={MOBILE_APP_LINK.play_store} />
          </div>
        </div>
      </div>

      {IS_SSHL_PAYMENT_ACTIVE && (
        <div className="mt-[2.75rem] md:mt-[3.75rem]">
          <SslCommerceBanner />
        </div>
      )}
    </div>
  );
}

export default Middle;
