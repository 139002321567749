import Image from "next/image";
import React from "react";

function Loader() {
  return (
    <div className="w-full flex items-center justify-center min-h-screen h-full fixed inset-0 z-[1000] bg-[#1B2D49] bg-opacity-[0.3]">
      <div className="w-fit h-fit flex items-center justify-center p-[0.938rem] bg-white rounded-[1rem]">
        <Image
          src="/assets/icons/Spinner.gif"
          alt="loader"
          width={80}
          height={80}
        />
      </div>
    </div>
  );
}

export default Loader;
