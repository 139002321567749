const addValue = (key, value) => {
  if (!key || !value) return;

  localStorage.setItem(key, JSON.stringify(value));
};

const getValue = (key) => {
  if (!key) return;

  const item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return null;
};

const removeValue = (key) => {
  if (!key) return;

  localStorage.removeItem(key);
};

const LocalStorage = {
  addValue,
  getValue,
  removeValue,
};

export default LocalStorage;
