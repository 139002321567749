import Image from "next/image";
import { motion } from "framer-motion";
import { CrossIcon } from "@/components/data-display/Icon";

function RecentSearchModal({
  recentSearchData,
  setSelectedValue,
  deleteSearchData,
}) {
  return (
    <div>
      <p className="text-grayD4 pl-[1.5rem] pb-5">
        Recent {recentSearchData.length > 1 ? "Searches" : "Search"}
      </p>
      <div>
        {recentSearchData.map((history) => (
          <motion.div
            layout
            className="cursor-pointer flex items-center justify-between py-[0.563rem] rounded-[0.5rem] hover:bg-[#F1F3F6]"
            key={history.value}
            onClick={(e) => setSelectedValue(e, history.value)}
          >
            <div className=" pl-[1.5rem] flex items-center">
              <Image
                src="/assets/icons/search-history.svg"
                height={20}
                width={20}
                alt="recent-search-delete-icon"
              />
              <span className="ml-[0.875rem] line-clamp-1 break-all">
                {history.value}
              </span>
            </div>
            <button
              className="pr-[1.5rem] pl-4"
              onClick={(e) => {
                e.stopPropagation();
                deleteSearchData(history.value);
              }}
            >
              <CrossIcon />
            </button>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default RecentSearchModal;
