import { useRouter } from "next/router";
import React from "react";
import en from "@/localization/en.json";

function AuthModal() {
  const router = useRouter();

  return (
    <div className="flex flex-col space-y-[0.75rem] py-3">
      <button
        onClick={() => router.push(`/signin`)}
        className="w-40 h-[42px] text-[#FE7A00] border rounded-[10px] border-[#FE7A00] active:bg-[#E81E61] active:text-white hover:bg-[#E81E61] hover:text-white hover:border-[#E81E61]  transition delay-100 duration-300 ease-in-out"
      >
        {en["common.sign_in"]}
      </button>
    </div>
  );
}

export default AuthModal;
