import React from "react";
import Link from "next/link";

function MegaDropdown({ menuData, position }) {
  return (
    <div className="group relative ">
      <button className="text-grayD2 group-hover:text-[#E81E61] font-medium py-2 rounded inline-flex items-center">
        <span className="text-body1">
          <Link href={`/category/${menuData.slug}`}>{menuData.name}</Link>
        </span>
        {menuData?.menus.length > 0 && (
          <svg
            className="fill-current h-4 w-4 ml-[.5rem]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        )}
      </button>
      <div
        className={`hidden absolute text-[#1F2A3B] bg-white group-hover:block drop-shadow-[0_14px_42px_rgba(10,16,26,0.1)] rounded-[.75rem] w-auto z-[100] ${
          position == 0 ? "left-0" : "right-0"
        }`}
      >
        {menuData?.menus.length > 0 && (
          <div
            className={`flex justify-between flex-wrap py-[2.25rem] pr-[2.25rem] gap-y-[1.5rem] ${
              menuData?.menus.length <= 5 ? "w-[13.313rem]" : "w-[19.5rem]"
            } `}
          >
            {menuData?.menus?.map((submenu) => {
              return (
                <div
                  key={submenu.slug}
                  className={`d-block text-body1 hover:text-[#E81E61] ${
                    menuData?.menus.length <= 5 ? "w-full" : "w-1/2"
                  } pl-[2.25rem] line-clamp-2`}
                >
                  <Link href={`/category/${submenu.slug}`}>{submenu.name}</Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default MegaDropdown;
