/* eslint-disable no-undef */
import axios from "axios";
import { getSession } from "next-auth/react";
import { getGuestUser } from "../guest-user";

const serverEnv = process.env.NEXT_PUBLIC_SERVER;

const apiClient = axios.create({
  baseURL:
    serverEnv === "MOCK"
      ? process.env.NEXT_PUBLIC_API_BASE_MOCK_URL
      : process.env.NEXT_PUBLIC_API_BASE_URL,

  timeout: 20000,

  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

apiClient.interceptors.request.use(
  async function (config) {
    if (typeof window !== "undefined") {
      const session = await getSession();
      if (session) {
        config.headers.Authorization = `Bearer ${session.token}`;
      }

      const guestUser = getGuestUser();

      if (guestUser) {
        config.headers["X-Unique-Number"] = guestUser.id;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

export default apiClient;
