import { useMutation, useQuery } from "@tanstack/react-query";
import {
  postProductQuestionApi,
  getProductQuestionApi,
  getProductReviewsApi,
  postProductRequestApi,
  getProductSuggestionsApi,
} from "@/api/end-points/product";

export const usePostProductQuestion = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(postProductQuestionApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        data: res.data?.data,
        message: res.data.message,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        errors: error.response.data?.errors,
        message: error.message,
      });
    },
  });
};

export const useGetProductReview = (useQueryOptions) => {
  const { query } = useQueryOptions || {};
  const { slug, page } = query;
  return useQuery({
    queryKey: ["reviews", slug, page],
    queryFn: () => getProductReviewsApi(slug, page),
    keepPreviousData: true,
    ...useQueryOptions,
    select: (res) => {
      const reviews = res.data?.data;
      return reviews;
    },
  });
};

export const useGetProductQuestionList = (
  product_slug,
  page,
  useQueryOptions
) => {
  return useQuery({
    queryKey: ["product-questions", product_slug, page],
    queryFn: () => getProductQuestionApi(product_slug, page),
    keepPreviousData: true,
    ...useQueryOptions,
    select: (res) => {
      return res.data?.data;
    },
  });
};

export const usePostProductRequest = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(postProductRequestApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        data: res.data?.data,
        message: res.data.message,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        errors: error.response.data?.errors,
        message: error.message,
      });
    },
  });
};

export const useGetProductSuggestions = (keyword, useQueryOptions) => {
  return useQuery({
    queryKey: ["searchSuggestions"],
    queryFn: () => getProductSuggestionsApi(keyword),
    ...useQueryOptions,
    select: (res) => {
      const suggestions = res.data?.data?.products || [];

      return suggestions;
    },
  });
};
