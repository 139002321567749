import ErrorToast from "@/components/Toast/ErrorToast";
import InfoToast from "@/components/Toast/InfoToast";
import NoInternetErrorToast from "@/components/Toast/NoInternetErrorToast";
import SuccessToast from "@/components/Toast/SuccessToast";
import WarningToast from "@/components/Toast/WarningToast";
import en from "@/localization/en.json";
import Styles from "@/utils/toast/toast.module.css";
import { toast } from "react-toastify";

const toastOptions = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  closeButton: false,
  icon: false,
  className: Styles.toast_wrapper,
  limit: 1,
};

export const successToast = (
  message = en["common.success"],
  autoClose = 1000
) =>
  toast.success(<SuccessToast message={message} />, {
    ...toastOptions,
    autoClose: autoClose,
    bodyClassName: Styles.success_body,
  });

export const errorToast = (message = en["common.error"]) => {
  toast.error(<ErrorToast message={message} />, {
    ...toastOptions,
    bodyClassName: Styles.error_body,
  });
};

export const noInternetErrorToast = (message = "No internet connection") => {
  toast.error(<NoInternetErrorToast message={message} />, {
    ...toastOptions,
    bodyClassName: Styles.error_body,
  });
};

export const infoToast = (message = en["common.info"]) => {
  toast.error(<InfoToast message={message} />, {
    ...toastOptions,
    bodyClassName: Styles.info_body,
  });
};

export const warningToast = (message = en["common.warning"], delay = 0) => {
  toast.error(<WarningToast message={message} />, {
    ...toastOptions,
    delay,
    bodyClassName: Styles.warning_body,
  });
};

export default successToast;
