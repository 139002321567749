import apiClient from "@/services/api-client";
import { errorToast } from "@/utils/toast";
import { purchaseEvent } from "lib/fpixel";
import { errorLogger } from "@/utils/helpers/logger";

export const createOrder = async (payload, totalPrice) => {
  try {
    const res = await apiClient({
      method: "post",
      url: "/v1/orders",
      data: payload,
    });

    if (res.status === 200) {
      purchaseEvent(totalPrice);
      return res.data;
    }
  } catch (error) {
    errorLogger.logApiError(error);
    errorToast(error?.response?.data?.message);
  }
};

export const fetchAllOrders = async () => {
  try {
    return await apiClient.get("/v1/orders");
  } catch (error) {
    errorLogger.logApiError(error);
    errorToast(error?.response?.data?.message);
  }
};

export const fetchSingleOrder = async (orderId) => {
  try {
    return await apiClient.get(`/v1/orders/${orderId}`);
  } catch (error) {
    errorLogger.logApiError(error);
    errorToast(error?.response?.data?.message);
    return error.response;
  }
};

export const fetchOrders = async (page, orderStatus, filter) => {
  try {
    return await apiClient.get(
      `/v1/orders?status=${orderStatus}&filter=${filter}&page=${page}`
    );
  } catch (error) {
    errorLogger.logApiError(error);
    errorToast(error?.response?.data?.message);
  }
};

export const getOrderByStatus = async (orderStatus) => {
  try {
    return await apiClient.get(`/v1/orders?status=${orderStatus}&page=1`);
  } catch (error) {
    errorLogger.logApiError(error);
    errorToast(error?.response?.data?.message);
  }
};

export const deleteOrder = async (orderId, payload) => {
  try {
    const res = await apiClient({
      method: "post",
      url: `/v1/orders/cancel/${orderId}`,
      data: payload,
    });
    if (res?.status === 200) return res;
  } catch (error) {
    errorLogger.logApiError(error);
    errorToast(error?.response?.data?.message);
  }
};

export const fetchOrderStatus = async () => {
  try {
    return await apiClient.get("/v1/orders/status");
  } catch (error) {
    errorLogger.logApiError(error);
    errorToast(error?.response?.data?.message);
  }
};

export const filterOrder = async (orderStatus, data) => {
  try {
    return await apiClient.get(
      `/v1/orders?status=${orderStatus}&filter_date_range=${data}&page=1`
    );
  } catch (error) {
    errorLogger.logApiError(error);
    errorToast(error?.response?.data?.message);
  }
};

export const fetchCancelationReason = async () => {
  try {
    const resData = await apiClient.get("v1/orders/cancel");
    if (resData.status === 200) return resData;
  } catch (error) {
    errorLogger.logApiError(error);
    errorToast(error?.response?.data?.message);
  }
};

export const orderTrackByNumber = async (data) => {
  try {
    return await apiClient({
      method: "post",
      url: `/v1/orders/tracking`,
      data,
    });
  } catch (error) {
    errorLogger.logApiError(error);
    return error.response;
  }
};

export const orderInvoiceDownload = async (orderId) => {
  try {
    return await apiClient({
      method: "get",
      url: `/v1/orders/invoice/${orderId}`,
      headers: {
        Accept: "application/pdf",
      },
      responseType: "blob",
    });
  } catch (error) {
    errorLogger.logApiError(error);
    return error.response;
  }
};
