import * as Yup from "yup";
import en from "@/localization/en.json";
import { phoneRegex } from "@/utils/patterns";

export const nameSchema = Yup.object({
  name: Yup.string()
    .required("Full name is a required field")
    .min(3, "Full name must be at least 3 characters")
    .max(30, "Full name must be at most 30 characters"),

  gender: Yup.string().required(en["signup.gender_is_required"]).nullable(),
});

export const emailSchema = Yup.object({
  email: Yup.string().required().email(),
});

export const phoneSchema = Yup.object({
  phone_number: Yup.string()
    .required(en["common.phone_number.required"])
    .matches(phoneRegex, en["signup.invalid_phone_number"]),
});
