import React, { useRef } from "react";
import TrackOrderForm from ".";

function MobileView() {
  const trackOrderModalCloseRef = useRef();
  const ref = useRef(null);

  const closeTrackOrderModal = () => {
    trackOrderModalCloseRef.current.click();
  };

  return (
    <>
      <input type="checkbox" id="order-track-modal" className="modal-toggle" />
      <div className="modal overflow-hidden">
        <div className={`modal-box relative `}>
          <label
            htmlFor="order-track-modal"
            ref={trackOrderModalCloseRef}
            className="absolute right-5 top-2 cursor-pointer text-[#414E5F] font-bold p-2"
            onClick={() => ref.current.reset()}
          >
            ✕
          </label>

          <div className="">
            <TrackOrderForm
              ref={ref}
              onSuccessFullOrderSearch={closeTrackOrderModal}
              onCloseFormRequest={closeTrackOrderModal}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileView;
