import PropTypes from "prop-types";

function Indicator({ itemCount, children, className }) {
  const MAX_COUNT_SHOW = 99;

  return (
    <button className={`indicator ${className}`}>
      {itemCount > 0 && (
        <span className="indicator-end indicator-item h-[1.375rem] w-[1.375rem]  lg:h-[1.625rem] lg:w-[1.625rem] rounded-full bg-primary-default px-1 py-1 text-center text-[0.625rem] lg:text-[0.75rem] text-white">
          {itemCount > MAX_COUNT_SHOW ? `99+` : itemCount}
        </span>
      )}
      {children}
    </button>
  );
}

Indicator.propTypes = {
  itemCount: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Indicator;
