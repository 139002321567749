import Cookies from "js-cookie";
import store from "@/store/index";
import { getSession } from "next-auth/react";

export const isAuthenticated = async (router) => {
  const session = await getSession();
  if (session) return true;

  router.push({
    pathname: "/signin",
    query: { from: router.asPath },
  });

  return false;
};

export const setAmarPetAccessToken = (token, expiresTimeInDay = 365) => {
  Cookies.set("amar_pet_access_token", token, { expires: expiresTimeInDay });
  store.dispatch(
    {
      type: "auth/updateAuthToken",
      payload: token,
    },
    { root: true }
  );
};

export const setAmarPetUser = (user, expiresTimeInDay = 365) => {
  Cookies.set("amar_pet_user", JSON.stringify(user), {
    expires: expiresTimeInDay,
  });
};

export const clearAmarpetCredentails = () => {
  Cookies.remove("amar_pet_access_token");
  Cookies.remove("amar_pet_user");
};

export const getAmarPetUser = () => {
  const user = Cookies.get("amar_pet_user");
  if (user) return JSON.parse(user);

  return { full_name: "", phone_number: "" };
};

export const getAmarPetToken = () => {
  return Cookies.get("amar_pet_access_token");
};
