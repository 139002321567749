import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItemsCount: 0,
  cartItems: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateCartItemCount: (state, action) => {
      state.cartItemsCount = action.payload;
    },

    updateCartItems: (state, action) => {
      state.cartItems = action.payload;
    },
  },
});

export default cartSlice.reducer;

export const { updateCartItemCount, updateCartItems } = cartSlice.actions;
