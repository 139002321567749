import Image from "next/image";

function SslCommerceBanner() {
  return (
    <div>
      <div className="hidden lg:block">
        <Image
          src="/assets/banner/SSLCOMMERZ-desktop.png"
          alt="ssl-commerce"
          width={5011}
          height={587}
        />
      </div>
      <div className="hidden md:block lg:hidden">
        <Image
          src="/assets/banner/SSLCOMMERZ-tab.png"
          alt="ssl-commerce"
          width={3751}
          height={844}
        />
      </div>

      <div className="md:hidden">
        <Image
          src="/assets/banner/SSLCOMMERZ-mobile.png"
          alt="ssl-commerce"
          width={1058}
          height={2448}
        />
      </div>
    </div>
  );
}

export default SslCommerceBanner;
