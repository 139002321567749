import Link from "next/link";
import Image from "next/image";
import { useState, useRef } from "react";

import { MobileView } from "../menubar";
import AuthModal from "@/components/modal/AuthModal";
import InteractiveToolTip from "@/components/tooltip/Interactive";
import ProfileDropdown from "@/components/modal/profile-dropdown";
import { useSelector } from "react-redux";
import Indicator from "@/components/data-display/Indicator";
import SearchForm from "@/components/actions/form/SearchForm";
import {
  AccountIcon,
  CartIcon,
  WishlistIcon,
} from "@/components/data-display/Icon";

import { useSession } from "next-auth/react";

function NavBar(props) {
  const { menuOptions } = props;
  const { data: session } = useSession();

  const mobileViewSidebarRef = useRef(null);
  const [instance, setInstance] = useState(null);

  const showMobileSideMenu = () => {
    mobileViewSidebarRef.current.showSideMenu();
  };

  let cartItemsCount = useSelector((state) => state.cart.cartItemsCount);
  let wishlistItemsCount = useSelector(
    (state) => state.wishlist.wishlistItemsCount
  );

  return (
    <>
      <div className="grid grid-cols-2 px-[1.25rem] py-8 md:flex md:items-center md:justify-between lg:px-[4rem]  xl:px-[7.5rem]">
        <div className="order-1 flex cursor-pointer items-center">
          <div className="block min-[1200px]:hidden mr-5">
            <Image
              src="/assets/icons/3-bar.svg"
              width={23}
              height={20}
              alt="menu logo"
              onClick={showMobileSideMenu}
            />
          </div>
          <Link href="/">
            <div className="relative h-[2.125rem] w-[7.688rem] md:h-[2.5rem] md:w-[8.75rem] lg:h-[3rem] lg:w-[11rem]">
              <Image
                src="/assets/icons/amar-pet.svg"
                alt="amar pet logo"
                priority
                fill
                sizes="640px"
              />
            </div>
          </Link>
        </div>

        <SearchForm />

        <div className="order-2 flex items-center space-x-4 lg:space-x-[1.625rem] justify-self-end md:order-3">
          <div className="">
            <InteractiveToolTip
              placement="bottom-end"
              content={
                session?.token ? (
                  <ProfileDropdown instance={instance} />
                ) : (
                  <AuthModal />
                )
              }
              hideElement={(data) => setInstance(data)}
            >
              <AccountIcon />
            </InteractiveToolTip>
          </div>

          <Link href="/my-wishlist">
            <div>
              <Indicator
                itemCount={wishlistItemsCount}
                className="hidden lg:block"
              >
                <WishlistIcon />
              </Indicator>
            </div>
          </Link>

          <Link href="/my-cart">
            <div>
              <Indicator itemCount={cartItemsCount}>
                <CartIcon />
              </Indicator>
            </div>
          </Link>
        </div>

        <MobileView ref={mobileViewSidebarRef} menuOptions={menuOptions} />
      </div>
    </>
  );
}

export default NavBar;
