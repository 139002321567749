const { useState, useRef, useEffect } = require("react");

const useCounter = (
  onTimerComplete = () => {},
  timeLimit = 60,
  timeLimitStr = "1:00"
) => {
  const Ref = useRef(null);

  const [timer, setTimer] = useState("00:00");
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);

    if (minutes <= 0 && seconds <= 0) {
      clearInterval(Ref.current);
      setIsTimerCompleted(true);
      onTimerComplete();
    }

    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer(timeLimitStr);

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + timeLimit);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());

    return () => {
      clearInterval(Ref.current);
    };
  }, []);

  const resetTimer = () => {
    setIsTimerCompleted(false);
    clearTimer(getDeadTime());
  };

  return { resetTimer, timer, isTimerCompleted };
};

export default useCounter;
