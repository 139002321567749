import jwt from "jsonwebtoken";

export function createJwtToken() {
  const currentTime = Date.now();

  const token = jwt.sign(
    { project: "APN081", time: currentTime },
    process.env.NEXT_PUBLIC_JWT_SECRET_TOKEN
  );

  return token;
}
