import PropTypes from "prop-types";
import Image from "next/image";
import Link from "next/link";

function SearchSuggestions({ query, searchedProducts }) {
  const MAX_DISPLAYED_PRODUCTS = 6;

  if (searchedProducts?.length > 0) {
    return (
      <div className="px-[0.5rem]">
        <div className="flex flex-col gap-[1rem]">
          {searchedProducts.slice(0, MAX_DISPLAYED_PRODUCTS).map((product) => {
            return (
              <Link
                href={`/product/${product.slug}`}
                prefetch={false}
                key={product.slug}
                className="flex cursor-pointer items-start gap-[0.938rem] rounded-[0.5rem] py-[0.75rem] pl-[0.5rem] pr-[0.75rem] hover:text-primary md:px-[1rem]"
              >
                <Image
                  src={product.main_picture}
                  alt={product.name}
                  height={54}
                  width={54}
                  sizes="54px"
                />
                <div className="flex flex-col gap-[0.125rem]">
                  <p className="text-[1rem] leading-[1.5rem] text-grayDisplay-900">
                    {product.name}
                  </p>
                  <p className="text-[1rem] font-semibold leading-[1.063rem] tracking-[0.08px]">
                    ৳{product.offer_price ? product.offer_price : product.price}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
        <div className="mt-[1.75rem] text-center text-[1rem] font-semibold leading-[1.063rem] tracking-[0.08px] text-primary">
          <Link role="button" href={`/search?query=${query}`}>
            See All Results
          </Link>
        </div>
      </div>
    );
  }

  return null;
}

SearchSuggestions.propTypes = {
  searchedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      product_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      main_picture: PropTypes.string,
      price: PropTypes.number.isRequired,
    })
  ),
};

export default SearchSuggestions;
