import en from "@/localization/en.json";
import { PropTypes } from "prop-types";

export default function NoInternetErrorToast({
  message = en["common.no_internet"],
}) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-[10px]">
        <div className="text-[1rem] text-[#EF393F] font-bold">{message}</div>
      </div>

      <div
        style={{
          maxWidth: "19px",
          maxHeight: "19px",
          minWidth: "19px",
          minHeight: "19px",
        }}
        className="cursor-pointer"
      >
        ✕
      </div>
    </div>
  );
}

NoInternetErrorToast.propTypes = {
  message: PropTypes.string,
};
