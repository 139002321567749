import apiClient from "@/services/api-client";
import { useQuery } from "@tanstack/react-query";
import { errorLogger } from "@/utils/helpers/logger";

const fetchMainMenuList = () => {
  return apiClient.get("/v1/main-menus");
};

export const useFetchMainMenuList = (useQueryOptions) => {
  const {
    onSuccess = () => {},
    onError = () => {},
    enabled = true,
  } = useQueryOptions || {};

  return useQuery({
    queryKey: ["main-menu"],
    queryFn: fetchMainMenuList,
    onSuccess,
    enabled,
    select: (res) => {
      let apiData = res.data?.data;
      return {
        mainMenuData: apiData?.main_menu,
        cartProductCount: apiData?.cart_product_count,
        wishlistProductCount: apiData?.wishlist_product_count,
        topBannerInfo: apiData?.top_banner_info,
        promotionalInfo: apiData?.welcome_popup_info,
      };
    },
    onError: (error) => {
      errorLogger.logApiError(error);
      onError(error);
    },
  });
};
