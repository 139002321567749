import uniqid from "uniqid";
import Cookies from "js-cookie";

export const generateGuestUser = () => {
  let id = uniqid();
  const time = Date.now().toString();
  let lastFourChar = time.substring(time.length - 4);
  id = id + lastFourChar;

  return { id };
};

export const setGuestUser = (guestUser, expiresTimeInDay = 365) => {
  if (!guestUser) return;

  Cookies.set("amar_pet_guest_user", JSON.stringify(guestUser), {
    expires: expiresTimeInDay,
  });
};

export const getGuestUser = () => {
  const guestUser = Cookies.get("amar_pet_guest_user");

  if (guestUser) {
    return JSON.parse(guestUser);
  }

  return null;
};

export const removeGuestUser = () => {
  Cookies.remove("amar_pet_guest_user");
};
