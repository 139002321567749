import { useEffect, useState, useRef } from "react";
import _ from "lodash";
import Image from "next/image";
import MegaDropdown from "./MegaDropdown";
import Link from "next/link";
import en from "@/localization/en.json";
import BrandDropdown from "./BrandDropdown";
import { IS_BKASH_OFFER_MENU_ACTIVE } from "config/feature-flag/menubar";

function Menubar({ menuOptions, brandData }) {
  const [isLogoHidden, setIsLogoHidden] = useState(true);
  const navRef = useRef();

  const toggleLogo = ([e]) => {
    setIsLogoHidden(!(e.intersectionRatio < 1));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(toggleLogo, {
      threshold: [1],
    });
    const current = navRef.current;

    if (navRef.current) {
      observer.observe(navRef.current);

      return () => {
        observer.unobserve(current);
      };
    }
  }, [isLogoHidden]);

  return (
    <nav
      className="hidden min-[1200px]:block min-[1200px]:sticky md:top-[-1px] min-[1200px]:shadow-[0_6px_5px_-2px_rgba(27,45,73,0.06)] px-8 min-[1200px]:px-[7.5rem] py-[1rem] bg-white
    z-[50]"
      ref={navRef}
    >
      <div className="flex items-center justify-between cursor-pointer">
        {!isLogoHidden && (
          <Link href="/">
            <div>
              <Image
                src="/assets/icons/amar-pet.svg"
                width={181}
                height={48}
                alt="amar pet logo"
              />
            </div>
          </Link>
        )}

        <Link href="/blogs">
          <div className="flex items-center py-2">
            <p className="font-medium tracking-[.64px] ml-[.5rem] py-2 cursor-pointer hover:text-[#E81E61]">
              Pets Lifestyle
            </p>
          </div>
        </Link>

        <Link href="/smart-pet-tag">
          <div className="flex items-center py-2">
            <p className="font-medium tracking-[.64px] ml-[.5rem] py-2 cursor-pointer hover:text-[#E81E61]">
              Smart Pet Tag
            </p>
          </div>
        </Link>

        {!_.isEmpty(menuOptions) &&
          menuOptions.map((menuOption, index) => {
            return (
              <MegaDropdown
                menuData={menuOption}
                key={menuOption.slug}
                position={index}
              />
            );
          })}

        <div>
          <BrandDropdown bandOptions={brandData} />
        </div>
        {IS_BKASH_OFFER_MENU_ACTIVE && (
          <Link href={`/bkash-offer`}>
            <div>
              <p className="font-medium tracking-[.64px] ml-[.5rem] py-2 cursor-pointer hover:text-[#E81E61]">
                bKash Offer
              </p>
            </div>
          </Link>
        )}

        <Link href={`/offers`}>
          <div className="flex items-center py-2">
            <Image
              src="/assets/icons/gift.svg"
              width={18}
              height={18}
              alt="arrow"
            />

            <p className="text-secondary text-center font-medium tracking-[.64px] ml-[.5rem] mt-[0.2rem] cursor-pointer">
              {en["home.offers"]}
            </p>
          </div>
        </Link>
      </div>
    </nav>
  );
}

export default Menubar;
