import apiClient from "@/services/api-client";
import { useQuery, useMutation } from "@tanstack/react-query";
import { errorLogger } from "@/utils/helpers/logger";
import {
  unAuthorizeOrderGetApi,
  unAuthorizeOrderTrackApi,
} from "@/api/end-points/order";

const fetchOrderStatus = () => {
  return apiClient.get("/v1/orders/status");
};

const fetchOrders = (page, orderStatus, filter) => {
  return apiClient.get(
    `/v1/orders?status=${orderStatus}&filter=${filter}&page=${page}`
  );
};

export const fetchOrderById = (orderId) => {
  if (!orderId) return;
  return apiClient.get(`/v1/orders/${orderId}`);
};

export const useFetchOrderStatus = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useQuery({
    queryKey: ["order-status-list"],
    queryFn: fetchOrderStatus,
    onSuccess,
    select: (data) => {
      let apiResponseData = data.data?.data;
      return apiResponseData?.orderStatus;
    },
    onError: (error) => {
      errorLogger.logApiError(error);
      onError(error);
    },
  });
};

export const useFetchOrders = (useQueryOptions) => {
  const {
    onSuccess = () => {},
    onError = () => {},
    query,
  } = useQueryOptions || {};

  let pageNumber = query?.page ? parseInt(query?.page) : 1;
  let orderStatus = query?.status ? parseInt(query?.status) : 0;
  let filter = query?.filter ? query?.filter : "all";

  return useQuery({
    queryKey: ["orders", pageNumber, orderStatus, filter],
    queryFn: () => fetchOrders(pageNumber, orderStatus, filter),
    onSuccess,
    keepPreviousData: true,
    select: (res) => {
      return res.data?.data;
    },
    onError: (error) => {
      errorLogger.logApiError(error);
      onError(error);
    },
  });
};

export const useFetchOrderById = (useQueryOptions) => {
  const {
    onSuccess = () => {},
    onError = () => {},
    orderId,
  } = useQueryOptions || {};

  return useQuery({
    queryKey: ["order", orderId],
    queryFn: () => fetchOrderById(orderId),
    onSuccess,
    enabled: Boolean(orderId),
    select: (data) => {
      let apiData = data.data?.data;
      return apiData?.order;
    },
    onError: (error) => {
      errorLogger.logApiError(error);
      onError(error);
    },
  });
};

export const useFetchTrackOrderById = (useQueryOptions) => {
  const {
    onSuccess = () => {},
    onError = () => {},
    orderId,
  } = useQueryOptions || {};

  return useQuery({
    queryKey: ["track-order", orderId],
    queryFn: () => unAuthorizeOrderGetApi(orderId),
    onSuccess,
    enabled: Boolean(orderId),
    select: (data) => {
      let apiData = data.data?.data;
      return apiData?.order;
    },
    onError: (error) => {
      errorLogger.logApiError(error);
      onError(error);
    },
  });
};

export const useUnAuthorizeOrderTrack = (useQueryOptions) => {
  const { onSuccess = () => {}, onError = () => {} } = useQueryOptions || {};

  return useMutation(unAuthorizeOrderTrackApi, {
    onSuccess: (res) => {
      onSuccess({
        status: res.status,
        data: res.data?.data,
        message: res.data.message,
      });
    },
    onError: (error) => {
      onError({
        status: error.response.status,
        errors: error.response.data?.errors,
        message: error.message,
      });
    },
  });
};
