import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shipping_address: null,
  delivery_method: 1,
  schedule_delivery_date: "",
  payment_method: 1,
  total_price: 0,
  orderedItems: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateShippingAddress: (state, action) => {
      state.shipping_address = action.payload;
    },
    updateDeliveryMethod: (state, action) => {
      state.delivery_method = action.payload;
    },

    updateDeliveryDate: (state, action) => {
      state.schedule_delivery_date = action.payload;
    },

    updatePaymentMethod: (state, action) => {
      state.payment_method = action.payload;
    },

    updateTotalPrice: (state, action) => {
      state.total_price = action.payload;
    },

    updateOrderedItems: (state, action) => {
      state.orderedItems = action.payload;
    },

    resetAllValue: (state) => {
      state.shipping_address = null;
      state.delivery_method = 1;
      state.schedule_delivery_date = "";
      state.payment_method = 1;
      state.total_price = 0;
    },
  },
});

export default orderSlice.reducer;
export const {
  updateShippingAddress,
  updateDeliveryMethod,
  updateDeliveryDate,
  updatePaymentMethod,
  updateTotalPrice,
  resetAllValue,
  updateOrderedItems,
} = orderSlice.actions;
