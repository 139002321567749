import { useQuery } from "@tanstack/react-query";
import { getNotificationApi } from "@/api/end-points/notification";

export const useGetNotification = (useQueryOptions) => {
  return useQuery({
    queryKey: ["notification"],
    queryFn: getNotificationApi,
    ...useQueryOptions,
    select: (res) => {
      return res.data?.data;
    },
  });
};
