import apiClient from "@/services/api-client";

export const getProductQuestionApi = (product_slug, page) => {
  return apiClient({
    method: "GET",
    url: `/v1/products/${product_slug}/questions?page=${page}`,
  });
};

export const getProductReviewsApi = (product_slug, page) => {
  return apiClient({
    url: `/v1/products/${product_slug}/reviews?page=${page}`,
    method: "GET",
  });
};

export const postProductQuestionApi = (payload) => {
  return apiClient({
    method: "POST",
    url: `/v1/products/${payload.product_slug}/questions`,
    data: { question: payload.question },
  });
};

export const postProductRequestApi = (payload) => {
  return apiClient({
    method: "POST",
    url: `/v1/products/${payload.product_slug}/stock-request`,
    data: { product_variation: payload.product_variation },
  });
};

export const getProductSuggestionsApi = (keyword) => {
  const params = keyword ? { query: keyword } : null;

  return apiClient({
    url: "/v1/products/suggestion",
    method: "GET",
    params,
  });
};
