import en from "@/localization/en.json";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

export default function Interactive({
  children,
  placement,
  content = () => <h1>{en["common.hello"]}</h1>,
  hideElement = () => null,
  trigger,
  onClickOutside,
}) {
  return (
    <Tippy
      trigger={trigger}
      interactive
      placement={placement}
      content={content}
      theme="light"
      onClickOutside={onClickOutside}
      hideOnClick={true}
      onShow={(instance) => hideElement(instance)}
    >
      <div>{children}</div>
    </Tippy>
  );
}

Interactive.defaultProps = {
  trigger: "mouseenter",
};
