import Link from "next/link";
import en from "@/localization/en.json";
import { DirectionIcon } from "@/components/data-display/Icon";

function OfficeDirectionBtn({ officeLink, className }) {
  return (
    <Link target="_blank" href={officeLink}>
      <button
        className={`${className} text-grayD2 text-body1 border-[1px] border-grayD5 rounded-[10px] flex items-center justify-center h-[2.75rem] w-[10.375rem]`}
      >
        <DirectionIcon />
        <span className="ml-[.62rem] text-[#1F2A3B]">
          {en["checkout.get_direction"]}
        </span>
      </button>
    </Link>
  );
}

export default OfficeDirectionBtn;
