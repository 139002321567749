/* eslint-disable @next/next/no-img-element */
import PropTypes from "prop-types";
import Image from "next/image";

function AppStoreLogo({ redirectUrl }) {
  return (
    <a href={redirectUrl} target="_blank" rel="noreferrer">
      <button>
        <Image
          alt="app-store-logo"
          src="/assets/logo/app-store.png"
          width={160}
          height={48}
        />
      </button>
    </a>
  );
}

AppStoreLogo.propTypes = {
  redirectUrl: PropTypes.string,
};

AppStoreLogo.defaultProps = {
  redirectUrl: "/",
};

export default AppStoreLogo;
