/* eslint-disable @next/next/no-img-element */
import PropTypes from "prop-types";
import Image from "next/image";

function PlayStoreLogo({ redirectUrl }) {
  return (
    <a href={redirectUrl} target="_blank" rel="noreferrer">
      <button>
        <Image
          alt="play-store-logo"
          src="/assets/logo/play-store.png"
          width={160}
          height={48}
        />
      </button>
    </a>
  );
}

PlayStoreLogo.propTypes = {
  redirectUrl: PropTypes.string,
};

PlayStoreLogo.defaultProps = {
  redirectUrl: "/",
};

export default PlayStoreLogo;
