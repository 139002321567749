import { useRef } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import PrimaryBtn from "@/components/button/primaryBtn";
import en from "@/localization/en.json";

function SubscriptionModal() {
  const router = useRouter();
  const modalCloseBtn = useRef();

  const redirectAndCloseModal = () => {
    modalCloseBtn.current.click();
    router.push("/");
  };

  return (
    <>
      <input type="checkbox" id="SubscriptionModal" className="modal-toggle" />
      <div className="modal overflow-hidden">
        <div
          className={`modal-box relative w-[90%] rounded-md lg:w-[640px] max-w-5xl`}
        >
          <label
            htmlFor="SubscriptionModal"
            className="absolute right-5 top-2 cursor-pointer text-[#414E5F] font-bold p-2"
            ref={modalCloseBtn}
          >
            ✕
          </label>

          <div className="">
            <div className="flex flex-col items-center pt-[4.438rem]">
              <Image
                src="/assets/icons/star.svg"
                width={90}
                height={86}
                alt="star-icon"
              />
              <p className="text-[#E81E61] text-[2rem] font-semibold mt-[1.5rem] mb-[1.125rem] w-[17.375rem] text-center lg:w-full">
                {en["home.thank_subscription"]}
              </p>
              <p className="text-[#414E5F] text-[1.063rem] text-center mb-[4rem]">
                {en["home.receive_promotions_1"]} <br />{" "}
                {en["home.receive_promotions_2"]}
              </p>

              <PrimaryBtn
                btnText={en["order.continue_shopping"]}
                className="h-[3.5rem] w-[18.75rem] mb-[2.7rem] lg:mb-[5rem]"
                onClick={redirectAndCloseModal}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscriptionModal;
