import useCounter from "@/utils/hooks/useCounter";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

function NoInternetConnectionToast() {
  const router = useRouter();
  const { timer } = useCounter();
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(window.navigator.onLine);
      if (window.navigator.onLine) router.reload();
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  const reloadPage = () => {
    if (!isOnline) return;
    else router.reload();
  };

  if (!isOnline)
    return (
      <div className="right-[50%] translate-x-[50%] fixed bg-[#FF7A00] top-[.5rem] text-white rounded px-[1rem] py-[.5rem] z-[500] flex">
        <span className="font-semibold mr-[5px]">Not connected.</span>
        <span className="mr-[5px]">Connecting in</span>
        {timer.split(":")[1]}s
        <span
          className="underline cursor-pointer ml-[5px]"
          onClick={reloadPage}
        >
          Try Now
        </span>
      </div>
    );
}

export default NoInternetConnectionToast;
