import { configureStore, combineReducers } from "@reduxjs/toolkit";
import loaderSlice from "./feature/loaderSlice";
import productSlice from "./feature/productSlice";
import cartSlice from "./feature/cartSlice";
import wishlistSlice from "./feature/wishlistSlice";
import orderSlice from "./feature/orderSlice";
import authSlice from "./feature/authSlice";
import promotionalSlice from "./feature/promotionalSlice ";

const combineReducer = combineReducers({
  product: productSlice,
  loader: loaderSlice,
  cart: cartSlice,
  wishlist: wishlistSlice,
  order: orderSlice,
  auth: authSlice,
  promotion: promotionalSlice,
});

const store = configureStore({
  reducer: combineReducer,
});

export default store;
