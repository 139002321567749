import en from "@/localization/en.json";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

function Topbar({ topMenuData }) {
  const [display, setDisplay] = useState(topMenuData?.status);

  return (
    <AnimatePresence>
      {display && (
        <motion.div
          exit={{ opacity: 0, height: 0 }}
          initial={{ height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          transition={{ duration: 0.4 }}
        >
          <div className="bg-darkPrimary text-white md:px-8 px-6 font-normal md:text-[0.938rem] text-[0.813rem] relative break-all text-center py-[0.625rem] ">
            <p
              className="w-[86%] lg:w-[95%] md:w-[90%] md:line-clamp-1 line-clamp-2 top-banner"
              dangerouslySetInnerHTML={{ __html: topMenuData?.content }}
            />
            <p
              className="absolute top-[50%] right-[.8%] translate-x-[-50%] translate-y-[-50%] cursor-pointer"
              onClick={() => setDisplay(false)}
            >
              - {en["home.close"]}
            </p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Topbar;
