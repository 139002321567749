import React from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { signOut } from "next-auth/react";

import { updateCartItemCount } from "@/store/feature/cartSlice";
import { updateWishlistItemCount } from "@/store/feature/wishlistSlice";
import en from "@/localization/en.json";
import successToast from "@/utils/toast";
import { generateGuestUser, setGuestUser } from "@/services/guest-user";

function ProfileDropdown({ instance }) {
  const dispatch = useDispatch();

  const router = useRouter();

  const closeDropDown = () => {
    if (instance) instance.hide();
  };

  const handleClick = (path) => {
    router.push(path);
    closeDropDown();
  };

  const handleSignOut = async () => {
    const res = await signOut({ redirect: false });
    if (res) {
      closeDropDown();
      dispatch(updateCartItemCount(0));
      dispatch(updateWishlistItemCount(0));
      router.push("/");
      successToast(en["common.logout_successfull"]);
      setGuestUser(generateGuestUser());
    }
  };

  return (
    <div className="text-[#0A101A] font-medium space-y-[0.5rem] mt-[1.063rem]  pb-[1.063rem] text-[1.125rem] px-[0.625rem]">
      <p
        className="px-[1.25rem] py-[0.688rem] rounded-[0.313rem] hover:bg-[#F1F3F6] cursor-pointer"
        onClick={() => handleClick("/my-account")}
      >
        {en["myAccount.profile"]}
      </p>
      <p
        className=" px-[1.25rem] py-[0.688rem] rounded-[0.313rem] hover:bg-[#F1F3F6] cursor-pointer"
        onClick={() => handleClick("/my-orders")}
      >
        {en["order.my_orders"]}
      </p>

      <p
        className=" px-[1.25rem] py-[0.688rem] rounded-[0.313rem] hover:bg-[#F1F3F6] cursor-pointer"
        onClick={() => handleClick("/my-wishlist")}
      >
        Wishlist
      </p>

      <p
        className="px-[1.25rem] py-[0.688rem] rounded-[0.313rem] hover:bg-[#F1F3F6] cursor-pointer"
        onClick={() => handleClick("/addresses")}
      >
        {en["addresses.my_addresses"]}
      </p>

      <p
        className="px-[1.25rem] py-[0.688rem] rounded-[0.313rem] hover:bg-[#F1F3F6] cursor-pointer"
        onClick={() => handleClick("/my-pets-profile")}
      >
        My Pet Profiles
      </p>
      <p
        className=" px-[1.25rem] py-[0.688rem] rounded-[0.313rem] hover:bg-[#F1F3F6] cursor-pointer"
        onClick={() => handleSignOut()}
      >
        {en["myAccount.log_out"]}
      </p>
    </div>
  );
}

export default ProfileDropdown;
