import Footer from "../footer";
import { useSelector } from "react-redux";
import Loader from "@/components/loader";
import NavBarWrapper from "./NavWrapper";

import ChatButton from "../actions/button/ChatButton";
import { messengerUrl } from "@/utils/constants/chat";

function Index({ children }) {
  let isLoading = useSelector((state) => state.loader.isLoading);

  return (
    <>
      <NavBarWrapper />

      {isLoading && (
        <div className="min-h-[200px]">
          <Loader />
        </div>
      )}
      {!isLoading && children}

      <a href={messengerUrl} target="_blank" rel="noreferrer">
        <ChatButton />
      </a>

      <Footer />
    </>
  );
}

export default Index;
