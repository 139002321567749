import React from "react";
import Top from "./Top";
import Middle from "./Middle";
import Bottom from "./Bottom";

function index() {
  return (
    <div>
      <div className="border-t-[0.5px] border-grayD6"></div>
      <Top />
      <div className="border-t-[0.5px] border-grayD6"></div>
      <Middle />
      <Bottom />
    </div>
  );
}

export default index;
