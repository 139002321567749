import Image from "next/image";

function ChatButton() {
  return (
    <div className="z-50 fixed bottom-[0.625rem] right-[0.625rem]">
      <div className="w-[3.5rem] h-[3.5rem] lg:w-[4.063rem] lg:h-[4.063rem] border-[0.5px] rounded-full bg-white border-primary/30 flex items-center justify-center cursor-pointer shadow-2xl animate-scaleUpDown hover:animate-none">
        <button className="relative w-[2.813rem]  h-[2.813rem] lg:w-[3.125rem] lg:h-[3.125rem]">
          <Image
            src="/assets/logo/massanger-icon.png"
            fill
            alt="messenger-icon"
            priority
          />
        </button>
      </div>
    </div>
  );
}

export default ChatButton;
