import React, { useRef, useEffect } from "react";
import Image from "next/image";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import SubscriptionModal from "../modal/Subscription-modal";
import { emailSchema } from "@/utils/validation/schema/profile";
import { successToast, errorToast } from "@/utils/toast";
import { socialMediaPagesLink } from "@/utils/constants/supportInfo";
import en from "@/localization/en.json";
import { useRouter } from "next/router";
import InlineLoader from "../loader/InlineLoader";
import { useSubscribeToNewsletter } from "repo/newsletter";
import statusCodes from "@/utils/constants/http-status-codes";

function Top() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const subscriptionModalRef = useRef();
  const router = useRouter();

  const {
    isLoading: isSubscribeToNewsletterLoading,
    mutate: subscribeToNewsletter,
  } = useSubscribeToNewsletter({
    onSuccess: (successRes) => {
      if (successRes.status === statusCodes.OK) {
        successToast(successRes.message);
      } else {
        subscriptionModalRef.current.click();
      }
      reset({ email: "" });
    },
    onError: (errorData) => {
      errorData.data.errors.forEach(({ name, message }) => {
        setError(name, { message });
      });
      errorToast(errorData.data.message);
    },
  });

  const getGRecaptchaToken = async () => {
    if (!executeRecaptcha) {
      return;
    }

    const gReCaptchaToken = await executeRecaptcha("newsLetterFormSubmit");

    return gReCaptchaToken;
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", clearNewsLetterSubscriptionField);

    return () => {
      router.events.off(
        "routeChangeComplete",
        clearNewsLetterSubscriptionField
      );
    };
  }, [router.events]);

  const handleSubscription = async (data) => {
    if (!data.email) {
      return setError("email", { message: "Enter your email" });
    }
    data.gRecaptchaToken = await getGRecaptchaToken();
    subscribeToNewsletter(data);
  };

  const {
    register,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(emailSchema),
  });

  const clearNewsLetterSubscriptionField = () => {
    reset({ email: "" });
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row sm:justify-between px-7 lg:px-[7.5rem] py-10">
        <div className="flex sm:items-center flex-col sm:flex-row space-y-3 sm:space-y-0">
          <div className="uppercase flex items-center space-x-1 sm:block sm:space-x-0">
            <p className=" text-[#7B838F] text-[15px]">
              {en["home.sign_up_to"]}
            </p>
            <p className="text-[1.375rem] font-semibold text-[#1F2A3B]">
              {en["home.newsletter"]}
            </p>
          </div>

          <div className="sm:ml-10 relative">
            {isSubscribeToNewsletterLoading && (
              <div className="absolute inset-0 z-50 bg-[#FFFFFFcc]">
                <InlineLoader />
              </div>
            )}
            <form
              className="border w-full min-w-[16rem] h-[2.875rem] border-[#CBD0D6] rounded-[2.625rem] relative md:w-[21.875rem] lg:w-[33.75rem]"
              onSubmit={handleSubmit(handleSubscription)}
            >
              <div className="w-full h-full rounded-[2.625rem]">
                <input
                  type="text"
                  className="w-full h-full rounded-[2.625rem] pl-4 border-0 focus:outline-none focus:border-[#CBD0D6] focus:ring-0 focus:ring-[#CBD0D6]"
                  placeholder="Enter your email address…"
                  name="email"
                  {...register("email")}
                  onBlur={(e) => !e.target.value && clearErrors("email")}
                />
              </div>

              <button
                className={`absolute h-[2rem] w-[2rem] rounded-full top-[50%] right-[5px] flex items-center justify-center hover:bg-[#E81E6114] translate-y-[-50%] cursor-pointer`}
              >
                <Image
                  src="/assets/icons/Glyph.svg"
                  alt="glyph"
                  width={22}
                  height={22}
                />
              </button>
            </form>
            {errors?.email && (
              <p className="text-[#FF4242] text-[0.875] mt-[0.35rem] first-letter:uppercase">
                {errors?.email?.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex items-center space-x-3 mt-8 sm:mt-0">
          {socialMediaPagesLink.facebook !== "" && (
            <a
              href={socialMediaPagesLink.facebook}
              target="_blank"
              rel="noreferrer"
            >
              <svg
                id="Group_1773"
                data-name="Group 1773"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 36 36"
                className="cursor-pointer fill-[#414e5f] hover:fill-[#e81e61]"
              >
                <g id="Group_1052" data-name="Group 1052">
                  <circle
                    id="Ellipse_46"
                    data-name="Ellipse 46"
                    cx="18"
                    cy="18"
                    r="18"
                    fill="#efeff3"
                    opacity="0"
                  />
                </g>
                <g id="facebook-logo" transform="translate(13 9)">
                  <path
                    id="Path_733"
                    data-name="Path 733"
                    d="M31.417,0,29.089,0a4.094,4.094,0,0,0-4.306,4.43V6.472H22.443a.367.367,0,0,0-.366.367V9.8a.367.367,0,0,0,.366.367h2.341v7.467A.366.366,0,0,0,25.15,18H28.2a.367.367,0,0,0,.366-.367V10.166h2.737a.366.366,0,0,0,.366-.367V6.839a.367.367,0,0,0-.366-.367H28.57V4.741c0-.832.2-1.255,1.279-1.255h1.568a.366.366,0,0,0,.366-.367V.371A.367.367,0,0,0,31.417,0Z"
                    transform="translate(-22.077)"
                  />
                </g>
              </svg>
            </a>
          )}

          {socialMediaPagesLink.twitter !== "" && (
            <a
              href={socialMediaPagesLink.twitter}
              target="_blank"
              rel="noreferrer"
            >
              <svg
                id="Group_4252"
                data-name="Group 4252"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 36 36"
                className="cursor-pointer fill-[#414e5f] hover:fill-[#e81e61]"
              >
                <circle
                  id="Ellipse_48"
                  data-name="Ellipse 48"
                  cx="18"
                  cy="18"
                  r="18"
                  fill="#e91f63"
                  opacity="0"
                />
                <path
                  id="Path_2162"
                  data-name="Path 2162"
                  d="M20.306,6.052a.092.092,0,0,0-.111-.137,7.845,7.845,0,0,1-1.891.472,3.807,3.807,0,0,0,1.587-1.861.089.089,0,0,0-.13-.108,7.584,7.584,0,0,1-2.145.789.093.093,0,0,1-.086-.028,3.782,3.782,0,0,0-6.451,3.363.094.094,0,0,1-.1.113,10.755,10.755,0,0,1-4.2-1.141,10.739,10.739,0,0,1-3.392-2.7.091.091,0,0,0-.15.014A3.828,3.828,0,0,0,2.8,6.617,3.766,3.766,0,0,0,4.48,9.759a3.743,3.743,0,0,1-1.577-.4.092.092,0,0,0-.135.084,3.894,3.894,0,0,0,.861,2.273,3.785,3.785,0,0,0,2.18,1.3,3.631,3.631,0,0,1-1.015.157,4.53,4.53,0,0,1-.56-.041.092.092,0,0,0-.1.121,3.792,3.792,0,0,0,3.484,2.473A7.593,7.593,0,0,1,2.948,17.35q-.261,0-.522-.017a.094.094,0,0,0-.058.173A10.69,10.69,0,0,0,7.829,19,10.682,10.682,0,0,0,18.636,8.239V7.8a.093.093,0,0,1,.037-.074A7.771,7.771,0,0,0,20.306,6.052Z"
                  transform="translate(6.677 7)"
                />
              </svg>
            </a>
          )}

          {socialMediaPagesLink.instagram !== "" && (
            <a
              href={socialMediaPagesLink.instagram}
              target="_blank"
              rel="noreferrer"
            >
              <svg
                id="Group_1780"
                data-name="Group 1780"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 36 36"
                className="cursor-pointer fill-[#414e5f] hover:fill-[#e81e61]"
              >
                <circle
                  id="Ellipse_47"
                  data-name="Ellipse 47"
                  cx="18"
                  cy="18"
                  r="18"
                  fill="#e9e9ee"
                  opacity="0"
                />
                <g id="instagram" transform="translate(10.002 10.002)">
                  <g id="Group_1778" data-name="Group 1778">
                    <path
                      id="Path_2163"
                      data-name="Path 2163"
                      d="M12,0H4A4.011,4.011,0,0,0,0,4v8a4.011,4.011,0,0,0,4,4h8a4.011,4.011,0,0,0,4-4V4A4.011,4.011,0,0,0,12,0ZM8,11.33A3.332,3.332,0,1,1,11.331,8,3.333,3.333,0,0,1,8,11.33ZM12.33,4.665a1,1,0,1,1,1-1A1,1,0,0,1,12.33,4.665Z"
                    />
                  </g>
                </g>
              </svg>
            </a>
          )}

          {socialMediaPagesLink.youtube !== "" && (
            <a
              href={socialMediaPagesLink.youtube}
              target="_blank"
              rel="noreferrer"
            >
              <svg
                id="Group_1781"
                data-name="Group 1781"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 36 36"
                className="cursor-pointer fill-[#414e5f] hover:fill-[#e81e61]"
              >
                <circle
                  id="Ellipse_48"
                  data-name="Ellipse 48"
                  cx="18"
                  cy="18"
                  r="18"
                  fill="#e9e9ee"
                  opacity="0"
                />
                <g id="youtube" transform="translate(9 11.698)">
                  <path
                    id="Subtraction_1"
                    data-name="Subtraction 1"
                    d="M9,12.6a53.01,53.01,0,0,1-7.042-.385A2.256,2.256,0,0,1,.371,10.632,23.805,23.805,0,0,1,0,6.3,23.88,23.88,0,0,1,.371,1.973,2.3,2.3,0,0,1,1.957.372,54.787,54.787,0,0,1,9,0a52.761,52.761,0,0,1,7.042.386,2.254,2.254,0,0,1,1.587,1.587A22.342,22.342,0,0,1,18,6.317a23.892,23.892,0,0,1-.371,4.329,2.257,2.257,0,0,1-1.587,1.587A54.913,54.913,0,0,1,9,12.6Zm-1.794-9h0V9l4.685-2.7L7.206,3.6Z"
                  />
                </g>
              </svg>
            </a>
          )}

          {socialMediaPagesLink.tiktok !== "" && (
            <a
              href={socialMediaPagesLink.tiktok}
              target="_blank"
              rel="noreferrer"
            >
              <svg
                id="Group_6419"
                data-name="Group 6419"
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                className="cursor-pointer fill-[#414e5f] hover:fill-[#e81e61]"
              >
                <circle
                  id="Ellipse_48"
                  data-name="Ellipse 48"
                  cx="18"
                  cy="18"
                  r="18"
                  fill="#e91f63"
                  opacity="0"
                />
                <g id="tik-tok" transform="translate(-21.422 8.609)">
                  <path
                    id="Path_20984"
                    data-name="Path 20984"
                    d="M47.414,4.9a4.624,4.624,0,0,1-2.773-.916A4.54,4.54,0,0,1,42.9,1.241,4.519,4.519,0,0,1,42.809.39H39.835V8.421l0,4.4a2.663,2.663,0,0,1-1.849,2.524,2.734,2.734,0,0,1-1,.131,2.7,2.7,0,0,1-1.231-.373,2.651,2.651,0,0,1,1.381-4.941,2.733,2.733,0,0,1,.849.135V7.311a5.919,5.919,0,0,0-.857-.063,5.732,5.732,0,0,0-4.284,1.894,5.477,5.477,0,0,0,.252,7.616,5.726,5.726,0,0,0,4.032,1.632,5.919,5.919,0,0,0,.857-.062,5.708,5.708,0,0,0,3.175-1.569,5.471,5.471,0,0,0,1.67-3.916l-.015-6.569a7.533,7.533,0,0,0,1.67.963,7.593,7.593,0,0,0,2.94.586V4.9h-.009Z"
                    transform="translate(0)"
                    stroke="#414e5e"
                    strokeWidth="0.3"
                  />
                </g>
              </svg>
            </a>
          )}
        </div>
      </div>
      <label
        htmlFor="SubscriptionModal"
        className="btn hidden"
        ref={subscriptionModalRef}
      >
        {en["home.open_modal"]}
      </label>
      <SubscriptionModal />
    </>
  );
}

export default Top;
