import apiClient from "@/services/api-client";
import { createJwtToken } from "@/services/jsonwebtoken";

export const postProductReview = ({ order_slug, product_slug, payload }) => {
  return apiClient({
    url: `/v1/orders/${order_slug}/reviews/${product_slug}`,
    method: "POST",
    data: payload,
  });
};

export const unAuthorizeOrderTrackApi = (data) => {
  return apiClient({
    url: `v1/orders/tracking-number`,
    method: "POST",
    data,
  });
};

export const unAuthorizeOrderGetApi = (orderKey) => {
  const token = createJwtToken();
  return apiClient({
    url: `v1/orders/track/${orderKey}?token=${token}`,
    method: "GET",
  });
};
