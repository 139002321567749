import en from "@/localization/en.json";
import { getCurrentYear } from "@/utils/helpers/date-time";

function Bottom() {
  return (
    <div className="px-7 lg:px-[7.5rem] pt-10 sm:pt-11 pb-5">
      <div className="border-t-[0.5px] border-grayD6 pb-5"></div>
      <div className="flex justify-center items-center">
        <div className="font-[0.875rem]">
          <span className="text-grayD2 font-semibold text-[0.875rem]">
            {en["home.amarpet"]}
          </span>
          <span className="font-light text-[#1F2A3B] text-[0.875rem]">
            © {getCurrentYear()} All Rights Reserved
          </span>
        </div>
      </div>
    </div>
  );
}

export default Bottom;
