import React from "react";
import Image from "next/image";
import Link from "next/link";
import en from "@/localization/en.json";

function MobileViewSubMenu({
  submenuOptions,
  selectedmenuIndex,
  allBrandsInfo,
  onBackBtnClick,
  hideSideMenu,
}) {
  return (
    <div>
      <div className="flex mt-[1rem]" onClick={onBackBtnClick}>
        <Image
          src="/assets/icons/left-arrow.svg"
          width={8}
          height={14}
          alt="phone logo"
        />
        <p className="grayD2 ml-[1.5rem]">{en["home.back"]}</p>
      </div>
      {selectedmenuIndex === -1 ? (
        <div>
          <h6 className="text-[#1F223B] font-medium text-heading6 mt-[3.313rem]">
            {en["home.shop_by_brands"]}
          </h6>

          <div className="flex flex-wrap">
            {allBrandsInfo &&
              allBrandsInfo.map((brand) => (
                <div key={brand.slug} onClick={hideSideMenu}>
                  <Link href={`/brands/${brand.slug}`}>
                    <div className="min-w-[4rem] h-[3.125rem] relative m-[.5rem]">
                      <Image
                        src={brand.logo}
                        alt="phone logo"
                        fill
                        className="object-contain"
                        sizes="33vw"
                      />
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <ul className="list-none mr-[1.5rem]">
          {submenuOptions &&
            submenuOptions.menus.map((menu) => {
              return (
                <li
                  key={menu.slug}
                  className="mt-[2.3rem]"
                  onClick={hideSideMenu}
                >
                  <Link href={`/category/${menu.slug}`} className="">
                    <p className="font-medium">{menu.name}</p>
                  </Link>
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
}

export default MobileViewSubMenu;
