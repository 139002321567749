import React, { useState, useRef } from "react";
import Link from "next/link";
import { useSession } from "next-auth/react";

import { contactInfo } from "@/utils/constants/supportInfo";
import InteractiveToolTip from "@/components/tooltip/Interactive";
import TrackOrderForm from "@/components/modal/track-order";
import MobileView from "@/components/modal/track-order/MobileView";
import en from "@/localization/en.json";
import { MOBILE_APP_ONE_LINK } from "@/utils/constants/mobileAppLink";
import {
  DownloadIcon,
  EmailIcon,
  PhoneIcon,
} from "@/components/data-display/Icon";

function InfoBar() {
  const [instance, setInstance] = useState(null);
  const ref = useRef(null);
  const { data: session } = useSession();
  const authToken = session?.token;

  return (
    <>
      <div className="bg-infoBarBg flex justify-between px-6 py-3 text-[0.75rem] text-grayD3">
        <div className="flex space-x-4 lg:space-x-5 items-center">
          <div className="flex space-x-2">
            <Link href={"tel:" + contactInfo.phone1}>
              <div className="cursor-pointer text-[15px] text-[#414e5f]">
                <PhoneIcon />
              </div>
            </Link>
            <div className="hidden md:block">
              <Link href={"tel:" + contactInfo.phone1}>
                {contactInfo.phone1}
              </Link>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <Link href={"mailto:" + contactInfo.email}>
              <div className="cursor-pointer text-[17px] md:text-base text-[#414e5f]">
                <EmailIcon />
              </div>
            </Link>
            <div className="hidden md:block">
              <Link href={"mailto:" + contactInfo.email}>
                {contactInfo.email}
              </Link>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <Link href={MOBILE_APP_ONE_LINK}>
              <div className="cursor-pointer text-base text-[#414e5f]">
                <DownloadIcon />
              </div>
            </Link>
            <div className="hidden md:block">
              <Link href={MOBILE_APP_ONE_LINK}>Download App</Link>
            </div>
          </div>
        </div>

        <div className="flex space-x-5 cursor-pointer">
          {authToken && (
            <div>
              <Link href={`/my-orders`}>
                <p className="hidden md:block">{en["order.my_orders"]}</p>
              </Link>
            </div>
          )}

          <div className="hidden lg:block">
            <InteractiveToolTip
              placement="bottom-end"
              trigger="click"
              content={
                <TrackOrderForm
                  ref={ref}
                  onCloseFormRequest={(reset) => {
                    reset();
                    instance.hide();
                  }}
                />
              }
              hideElement={(data) => {
                setInstance(data);
                ref.current.reset();
              }}
              onClickOutside={() => {
                ref.current.reset();
              }}
            >
              <p className="">{en["order.track_my_order"]}</p>
            </InteractiveToolTip>
          </div>
          <div className="lg:hidden">
            <label htmlFor="order-track-modal">Track My Order</label>
          </div>
          {authToken && (
            <div>
              <Link href={`/my-account`}>{session?.user?.full_name}</Link>
            </div>
          )}

          {!authToken && (
            <div className="space-x-1">
              <Link href="/signin">{en["common.sign_in"]}</Link>
            </div>
          )}
        </div>
      </div>
      <MobileView />
    </>
  );
}

export default InfoBar;
