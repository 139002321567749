import Cookies from "js-cookie";

export const setPromotionalBannerStatus = () => {
  const oneDay = 1;
  Cookies.set("promotional_banner_shown", JSON.stringify(1), {
    expires: oneDay,
  });
};

export const checkPromotionalBannerStatus = () => {
  const status = Cookies.get("promotional_banner_shown");
  if (status) return true;

  return false;
};
