import Image from "next/image";
import { PropTypes } from "prop-types";

export default function SuccessToast({ message }) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-[10px]">
        <div
          className="bg-[#10BB69] rounded-[10px] flex items-center justify-center"
          style={{
            maxWidth: "33px",
            maxHeight: "33px",
            minWidth: "33px",
            minHeight: "33px",
            marginRight: "10px",
          }}
        >
          <Image
            src="/assets/icons/toast-success-check.svg"
            alt="delete-btn"
            width={15}
            height={15}
          />
        </div>
        <div className="text-[1rem] text-[#10BB69] font-bold">{message}</div>
      </div>

      <div
        style={{
          maxWidth: "19px",
          maxHeight: "19px",
          minWidth: "19px",
          minHeight: "19px",
        }}
        className="cursor-pointer"
      >
        <Image
          src="/assets/icons/toast-delete.svg"
          alt="delete-btn"
          width={19}
          height={19}
        />
      </div>
    </div>
  );
}

SuccessToast.propTypes = {
  message: PropTypes.string,
};
