import Image from "next/image";
import { PropTypes } from "prop-types";

export default function InfoToast({ message }) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-[10px]">
        <div
          className="bg-[#2C75FF] rounded-full flex items-center justify-center"
          style={{
            maxWidth: "33px",
            maxHeight: "33px",
            minWidth: "33px",
            minHeight: "33px",
            marginRight: "10px",
          }}
        >
          <Image
            src="/assets/icons/toast-error-icon.svg"
            alt="delete-btn"
            width={26}
            height={26}
          />
        </div>

        {/* <div class="w-[70px] overflow-hidden inline-block">
          <div class=" h-[10px] w-[10px] bg-black rotate-45 transform origin-bottom-left"></div>
        </div> */}

        <div className="text-[1rem] text-[#2C75FF] font-bold">{message}</div>
      </div>

      <div
        style={{
          maxWidth: "19px",
          maxHeight: "19px",
          minWidth: "19px",
          minHeight: "19px",
        }}
        className="cursor-pointer"
      >
        <Image
          src="/assets/icons/toast-delete.svg"
          alt="delete-btn"
          width={19}
          height={19}
        />
      </div>
    </div>
  );
}

InfoToast.propTypes = {
  message: PropTypes.string,
};
