import React from "react";
import LoaderImage from "@/icons/inline-loader.svg";

function InlineLoader({ className }) {
  return (
    <LoaderImage
      className={`inline-block h-[3rem] w-[3rem] absolute top-[calc(50%_-_1.5rem)] left-[calc(50%_-_1.5rem)] ${className}`}
    />
  );
}

export default InlineLoader;
