/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import MetaData from "@/components/seo/MetaData";
import store from "@/store/index";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import * as fbq from "../lib/fpixel";
import { FB_PIXEL_ID } from "../lib/fpixel";

import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Layout from "@/components/layout";
import { closeLoader, startLoader } from "@/store/feature/loaderSlice";
import "../styles/globals.css";

import NoInternetConnectionToast from "@/components/error/NoInternetConnectionToast";
import { updateAuthToken } from "@/store/feature/authSlice";

import { appWithTranslation } from "next-i18next";
import {
  generateGuestUser,
  getGuestUser,
  setGuestUser,
} from "@/services/guest-user";
import { SessionProvider } from "next-auth/react";
import { getSession } from "next-auth/react";
import { Outfit } from "next/font/google";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const outfit = Outfit({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
});

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter();
  const handleRouteChange = (url) => {
    if (
      url.includes("?") ||
      url.includes("&") ||
      url.match(/^\/my-orders/) ||
      url.match(/^\/product/)
    ) {
    } else {
      store.dispatch(startLoader());
    }
  };

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
    });

    async function handleGuestUser() {
      let userData = await getSession();
      if (userData || getGuestUser()) return;

      setGuestUser(generateGuestUser());
    }
    handleGuestUser();
  }, []);

  useEffect(() => {
    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview();
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    router?.events?.on("routeChangeStart", (url) => handleRouteChange(url));
    router?.events?.on("routeChangeComplete", () =>
      store.dispatch(closeLoader())
    );
    router?.events?.on("routeChangeError", () => store.dispatch(closeLoader()));

    store.dispatch(updateAuthToken(Cookies.get("amar_pet_access_token"))); // set token to redux on page refresh

    return () => {
      router.events.off("routeChangeStart", (url) => handleRouteChange(url));
      router.events.off("routeChangeComplete", () =>
        store.dispatch(closeLoader())
      );
      router.events.off("routeChangeError", () =>
        store.dispatch(closeLoader())
      );
    };
  }, []);

  const RenderedLayout = Component.getLayout || Layout;

  return (
    <div className={`${outfit.className}`}>
      <MetaData />
      <SessionProvider session={session}>
        <NoInternetConnectionToast />
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
              scriptProps={{
                async: false,
                defer: false,
                appendTo: "head",
                nonce: undefined,
              }}
            >
              <RenderedLayout>
                <noscript>
                  <img
                    height="1"
                    width="1"
                    style={{ display: "none" }}
                    src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}
                  />
                </noscript>
                <Component {...pageProps} />
              </RenderedLayout>
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
            </GoogleReCaptchaProvider>
          </QueryClientProvider>
        </Provider>
        <ToastContainer />
      </SessionProvider>
    </div>
  );
}

export default appWithTranslation(MyApp);
